<template>
  <div class="row h-auto bg-light">
    <div class="col-12 h-auto px-5 pt-5">
      <ul class="nav nav-underline">
        <li v-if="cookie.user.id_profile < 5 || cookie.user.id_profile == 7" class="nav-item me-3">
          <router-link :to="{ name: 'Orders' }" class="nav-link pt-0" :class="{ 'active': isActive('/orders'), 'text-body-tertiary': !isActive('/orders') }">
            <h1>Commandes</h1>
          </router-link>
        </li>
        <li v-if="cookie.user.id_profile < 5" class="nav-item me-3">
          <router-link :to="{ name: 'Carts' }" class="nav-link pt-0" :class="{ 'active': isActive('/carts'), 'text-body-tertiary': !isActive('/carts') }">
            <h1>Paniers</h1>
          </router-link>
        </li>
        <li v-if="cookie.user.id_profile < 5" class="nav-item me-3">
          <router-link :to="{ name: 'Customers' }" class="nav-link pt-0" :class="{ 'active': isActive('/customers'), 'text-body-tertiary': !isActive('/customers') }">
            <h1>Clients</h1>
          </router-link>
        </li>
        <li v-if="cookie.user.id_profile < 5 || cookie.user.id_profile == 7" class="nav-item me-3">
          <router-link :to="{ name: 'Returns' }" class="nav-link pt-0" :class="{ 'active': isActive('/returns'), 'text-body-tertiary': !isActive('/returns') }">
            <h1>Retours</h1>
          </router-link>
        </li>
        <li class="nav-item me-3">
          <router-link :to="{ name: 'Products' }" class="nav-link pt-0" :class="{ 'active': isActive('/products'), 'text-body-tertiary': !isActive('/products') }">
            <h1>Produits</h1>
          </router-link>
        </li>
        <li v-if="cookie.user.id_profile < 5" class="nav-item me-3">
          <router-link :to="{ name: 'Refunds' }" class="nav-link pt-0" :class="{ 'active': isActive('/refunds'), 'text-body-tertiary': !isActive('/refunds') }">
            <h1>Remboursements</h1>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <router-view />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('cookie', [
      'cookie'
    ])
  },
  methods: {
    ...mapActions('search', [
      'clearSearch'
    ]),
    isActive(tab) {
      const page = this.$route.path;
      return page.includes(tab);
    }
  },
  unmounted() {
    this.clearSearch();
  }
};
</script>
